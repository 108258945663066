import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container mt-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "table table-striped" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "Вірш з Біблії"),
              _createElementVNode("th", null, "Місце"),
              _createElementVNode("th", null, "Дії")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.arrayVerse, (photo) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: photo.id
              }, [
                _createElementVNode("td", null, _toDisplayString(photo.title), 1),
                _createElementVNode("td", null, _toDisplayString(photo.verse), 1),
                _createElementVNode("td", null, [
                  _createElementVNode("button", {
                    onClick: _withModifiers(($event: any) => ($options.deleteText(photo.id)), ["prevent"]),
                    style: {"margin-top":"5px"}
                  }, _cache[0] || (_cache[0] = [
                    _createElementVNode("i", {
                      style: {"color":"#ba2626"},
                      class: "fas fa-trash-alt fa-2x"
                    }, null, -1)
                  ]), 8, _hoisted_5)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}